import 'braid-design-system/reset';
import { MelwaysProvider } from '@seek/melways-react';
import { VocabProvider } from '@vocab/react';
import React, { type FC } from 'react';
import { hydrateRoot } from 'react-dom/client';

import type { ConfigContextTypes } from 'src/shared/types';

import configContext from './config-context';
import pageMap from './pages';

const App = ({
  Component,
  config,
}: {
  Component: FC;
  config: ConfigContextTypes;
}) => (
  <configContext.Provider value={config}>
    <MelwaysProvider.Static
      language={config.language}
      path={config.route}
      site={config.site}
    >
      <VocabProvider language={config.language}>
        <Component />
      </VocabProvider>
    </MelwaysProvider.Static>
  </configContext.Provider>
);

export default (config: ConfigContextTypes) => {
  // @ts-ignore
  const Component = pageMap[config.routeName];

  hydrateRoot(
    document.getElementById('app')!,
    <App Component={Component} config={config} />,
  );
};
