import { MelwaysClientProvider } from './providers/client';
import { MelwaysServerProvider } from './providers/server';
import { MelwaysStaticProvider } from './providers/static';

export const MelwaysProvider = {
  Client: MelwaysClientProvider,
  Server: MelwaysServerProvider,
  Static: MelwaysStaticProvider,
};

export { useMelwaysInfo, useMelwaysLink } from './hooks';
