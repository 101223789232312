import loadable from 'sku/@loadable/component';

const BrowserUpgrade = loadable(
  () =>
    // @ts-ignore
    import('../pages/browser-upgrade/App/App'),
);
// @ts-ignore
const ContactUs = loadable(() => import('../pages/contact-us/App/App'));
const ClearBrowserHistory = loadable(
  () =>
    // @ts-ignore
    import('../pages/clear-browser-history/App/App'),
);

const WorkForSeek = loadable(
  () =>
    // @ts-ignore
    import('../pages/work-for-seek/WorkForSeek'),
);
const GraduateProgram = loadable(
  () =>
    // @ts-ignore
    import('../pages/work-for-seek/graduate-program/GraduateProgram'),
);
const WhyChooseSeek = loadable(
  () =>
    // @ts-ignore
    import('../pages/work-for-seek/why-choose-seek/WhyChooseSeek'),
);
const HowWeWork = loadable(
  () =>
    // @ts-ignore
    import('../pages/work-for-seek/how-we-work/HowWeWork'),
);
const HowWeHire = loadable(
  () =>
    // @ts-ignore
    import('../pages/work-for-seek/how-we-hire/HowWeHire'),
);

const AccountDeleted = loadable(
  () => import('../pages/account-deleted/App/App'),
);

const IdentityError = loadable(() => import('../pages/identity-error/App/App'));

const NotFound = loadable(() => import('../pages/error/not-found/App'));

const Forbidden = loadable(() => import('../pages/error/forbidden/App'));

const ServerError = loadable(() => import('../pages/error/server-error/App'));

const Maintenance = loadable(() => import('../pages/error/maintenance/App'));

export default {
  'browser-upgrade': BrowserUpgrade,
  'contact-us': ContactUs,
  'clear-browser-history': ClearBrowserHistory,
  'work-for-seek': WorkForSeek,
  'graduate-program': GraduateProgram,
  'why-choose-seek': WhyChooseSeek,
  'how-we-work': HowWeWork,
  'how-we-hire': HowWeHire,
  'account-deleted': AccountDeleted,
  'identity-error': IdentityError,
  'not-found': NotFound,
  forbidden: Forbidden,
  'server-error': ServerError,
  maintenance: Maintenance,
};
